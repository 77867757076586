<template>
  <div class="yx-wid yx-bg sj-layout-over">
    <div class="yx-wid banner">
      <img
        v-lazy="item.imgUrl"
        v-for="(item, index) in banners"
        :key="index"
        @click="openBanner(item)"
        :class="{ hover: index === bannerInd }"
      />
      <!-- 快捷banner导航 -->
      <div class="banner-nav">
        <span
          v-for="(item, index) in banners"
          :key="index"
          :class="{ hover: index === bannerInd }"
          @click="tabBanner(item, index)"
        ></span>
      </div>
      <!-- 左右切换 -->
      <div class="banner-prev" @click="tabPrev"></div>
      <div class="banner-next" @click="tabNext"></div>
    </div>

    <!-- 跑马灯 -->
    <div class="yx-wid home-ad">
      <div class="yx-wid-12">
        <div class="home-ad-icon"></div>
        <div class="home-ad-scroll" v-if="newsList" ref="scorll">
          <div
            class="home-ad-text"
            @mouseout="scrollStar"
            @mouseover="moveScroll"
            ref="scorllWid"
            :style="{
              transform: 'translateX(' + scorllLeftVal + 'px)',
              transition: scrollLeftStar,
            }"
          >
            <div
              class="row"
              v-for="(item, index) in newsList"
              @click="openMessage(item)"
              :key="index"
              v-html="item.scrollContent"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- APP下载 -->
    <div class="yx-wid-12 yx-load">
      <div class="sj-title">
        <h3>下载APP，</h3>
        <span class="span">指尖投注，随时随地畅玩。</span>
      </div>
      <div class="sj-download" v-if="baseInfo && baseInfo[22]">
        <div class="row">
          <div class="row-left">
            <h4>IOS下载</h4>
            <div class="code">
              <QRCode :text="baseText('iosApp')" :width="120" :height="120" />
              <p>扫码下载</p>
            </div>
            <div class="ym-add">
              手机打开：<a
                :href="baseInfo[22].configValue"
                target="_black"
                class="text-over"
                >{{ baseText("iosApp") }}</a
              >
            </div>
          </div>
          <div class="row-right">
            <img src="@/assets/images/download02.webp" />
          </div>
        </div>
        <div class="row">
          <div class="row-left">
            <h4>android下载</h4>

            <div class="code">
              <QRCode
                :text="baseText('androidApp')"
                :width="120"
                :height="120"
              />
              <p>扫码下载</p>
            </div>
            <div class="ym-add">
              手机打开：<a
                :href="baseInfo[22].configValue"
                target="_black"
                class="text-over"
                >{{ baseText("androidApp") }}</a
              >
            </div>
          </div>
          <div class="row-right">
            <img src="@/assets/images/download-01.webp" />
          </div>
        </div>
      </div>
    </div>
    <!-- 热门游戏 -->
    <section class="yx-wid-12 ab-hotgame">
      <div class="sj-title">
        <h3>游戏中心，</h3>
        <span class="span">甄选业内各类游戏，玩法丰富多样。</span>
      </div>
      <div class="sj-game">
        <div
          class="row"
          v-for="(item, index) in homeGames"
          :key="index"
          :style="{
            backgroundImage:
              'url(' +
              require('@/assets/images/home/' + item.layout + '.webp') +
              ')',
          }"
          @click="$router.push(item.link)"
        >
          <h3>{{ item.gameTypeName }}</h3>
          <p>{{ item.gameDescription }}</p>
        </div>
      </div>
    </section>
    <!-- 优质服务 -->
    <section class="yx-wid-12 ab-hotgame">
      <div class="sj-title">
        <h3>技术驱动娱乐，</h3>
        <span class="span">顶尖技术为您保驾护航。</span>
      </div>
      <div class="sj-service">
        <div
          class="row"
          v-for="(item, index) in serviceList"
          :key="index"
          :style="{
            backgroundImage: 'url(' + item.icon + ')',
          }"
        >
          <h3>{{ item.name }}</h3>
          <p>{{ item.sec }}</p>
        </div>
      </div>
    </section>

    <Bulletin
      v-if="ismsgShow && newsList"
      :visible.sync="ismsgShow"
      :listData="newsList"
    />
  </div>
</template>
<script>
import { WOW } from "wowjs";
import QRCode from "@/components/QRCode.vue";
import Bulletin from "@/components/Bulletin.vue";
import { mapState } from "vuex";
export default {
  components: { QRCode, Bulletin },
  name: "Home",
  data() {
    return {
      ismsgShow: false,
      serviceList: [
        {
          icon: require("@/assets/images/home/service01.png"),
          name: "安全",
          sec: "军用级系统防护和数据加密，客户信息和数据永不丢失",
        },
        {
          icon: require("@/assets/images/home/service02.png"),
          name: "稳定",
          sec: "全球多条网络线路加速，系统永不掉线",
        },
        {
          icon: require("@/assets/images/home/service03.png"),
          name: "资金",
          sec: "多元化支付渠道、更多币种选择、极速存取",
        },
        {
          icon: require("@/assets/images/home/service04.png"),
          name: "服务",
          sec: "24小时在线客服，一站式答疑和解决问题",
        },
      ],
      bannerInd: 0,
      bannerTimer: null,
      loads: [
        {
          name: "IOS APP",
          title: "iOS APP",
          code: "iosApp",
          type: 1,
          sec: "全球首家一体化娱乐原生APP ，尽显流畅，完美操作。海量体育，电竞顶尖赛事，真人娱乐，彩票投注及电子游艺等，最新最全娱乐项目尽在掌中体验扫码下载，即刻拥有！",
          list: [
            require("@/assets/images/load/ios01.png"),
            require("@/assets/images/load/ios02.png"),
            require("@/assets/images/load/ios03.webp"),
          ],
          link: "",
          class: "move",
        },
        {
          name: "Andriod APP",
          title: "andriod APP",
          code: "androidApp",
          type: 2,
          sec: "业内赔率最高！覆盖世界各地赛事，让球、大小、半全场、波胆、单双、总入球、连串过关等多元竞猜。更有动画直播，让您体验轻松聊球，娱乐投注两不误。",

          list: [
            require("@/assets/images/load/android01.webp"),
            require("@/assets/images/load/android02-1.webp"),
            require("@/assets/images/load/android02.png"),
            require("@/assets/images/load/android03.webp"),
            require("@/assets/images/load/android04.webp"),
          ],
          link: "",
          class: "",
        },
      ],
      loadInd: 0,
      liveTabs: [
        { name: "真人主播", type: "" },
        { name: "足球赛事", type: "" },
        { name: "篮球赛事", type: "" },
        { name: "电竞赛事", type: "" },
      ],
      liveInd: 0,
      hotsGameInd: 0,
      newsList: null,
      banners: null,
      canvas: null,
      context: null,
      flash: 0.1,
      scorllLeftVal: 0,
      scrollLeftStar: "all .4s",
      timerText: null,
      scorllWid: 0,
      isScroll: false,
      loadTargetName: "iosApp",
      loadTargetStyle: "",
      hotsMove: "",
      hotsMoveXh: "",
    };
  },
  computed: {
    ...mapState([
      "navsVal",
      "navsList",
      "isLogin",
      "baseInfo",
      "hotsGameList",
      "homeGames",
    ]),
  },
  watch: {
    navsVal(val) {
      if (!val) return;
      if (val) {
        this.initGameList();
      }
    },
    baseInfo(val) {
      if (val && val.length) {
        this.initBaseInfo();
      }
    },
  },
  mounted() {
    // this.autoBanner();
    this.getTop50MessageList();
    this.getSilde();
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
  methods: {
    // 基础信息赋值给data上
    initBaseInfo() {
      if (!this.baseInfo) return;
      this.loads.forEach((item) => {
        if (item.type === 1) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "androidApp"
          ).configValue;
        }
        if (item.type === 2) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "iosApp"
          ).configValue;
        }
      });
    },
    initGameList() {
      const arr = this.navsList.filter(
        (item) => !["0"].includes(item.gameType) && item.gameType
      );
      if (arr) {
        setTimeout(() => {
          this.$store.dispatch("setHotsGameListAc", arr);
        }, 500);
      }
    },
    viewMessage() {
      this.$router.push("/mail");
    },
    openMessage(val) {
      this.$confirm(val.content, val.title, {
        confirmButtonText: "查看全部公告",
        customClass: "el-pop-wid-message",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          if (!this.isLogin) return;
          this.$router.push("/mail");
        })
        .catch(() => {
          console.log(11);
        });
    },
    // banner
    getSilde() {
      const params = {
        position: 1,
        device: 1,
      };
      this.$Api.getSilde(params).then((res) => {
        if (res) {
          this.banners = res.slideList;
          this.autoBanner();
        }
      });
    },
    // 公告
    getTop50MessageList() {
      this.$Api.getTop50MessageList().then((res) => {
        if (res) {
          this.newsList = res.messageList;
          if (this.newsList && this.newsList.length) {
            // 设定24小时后重新开启公告通知弹窗
            const isDay = localStorage.getItem("messageAuto");
            if (!isDay && this.newsList.length) {
              this.ismsgShow = true;
            }
            if (isDay) {
              const targetDay = 24 * 60 * 60 * 1000; //一天秒数
              const isDYDay = new Date().getTime() - isDay; //获取当前日期是否超过一天
              if (isDYDay > targetDay) {
                localStorage.removeItem("messageAuto");
              }
            }
          }
          this.$nextTick(() => {
            this.scrollInit();
          });
        }
      });
    },
    moveScroll() {
      clearInterval(this.timerText);
    },
    // 跑马灯初始化
    scrollInit() {
      // 获取box宽度
      const dowSocrll = this.$refs.scorll.clientWidth;
      this.scorllWid = this.$refs.scorllWid.clientWidth;
      this.isScroll = this.scorllWid > dowSocrll ? true : false;
      if (this.isScroll) {
        this.newsList.push(...this.newsList);
        this.scrollStar();
      }
    },
    scrollStar() {
      if (!this.isScroll) return;
      this.timerText = setInterval(() => {
        const valNumber =
          this.scorllWid - this.scorllLeftVal < 10
            ? this.scorllWid - this.scorllLeftVal
            : 10;
        this.scorllLeftVal -= valNumber;
        if (this.scorllLeftVal <= -this.scorllWid) {
          this.scorllLeftVal = 0;
          this.scrollLeftStar = "none";
          setTimeout(() => {
            this.scrollLeftStar = "all .4s";
          }, 50);
        }
      }, 120);
    },
    tabLive(val, index) {
      this.liveInd = index;
    },
    TabHotsGame(val, index) {
      this.hotsMove = "out";
      setTimeout(() => {
        this.hotsMoveXh = "";
        this.hotsGameInd = index;
        this.hotsMove = "move";
        setTimeout(() => {
          this.hotsMove = "upMove";
        }, 500);
      }, 500);
    },
    autoBanner() {
      this.bannerTimer = setInterval(() => {
        this.bannerInd++;
        if (this.bannerInd >= this.banners.length) {
          this.bannerInd = 0;
        }
      }, 5000);
    },
    tabBanner(val, index) {
      clearInterval(this.bannerTimer);
      this.bannerInd = index;
      this.autoBanner();
    },
    openBanner(val) {
      if (val.activityId != null) {
        this.$router.push("/activity/activityLandPage?type=" + val.activityId);
      }
    },
    tabPrev() {
      clearInterval(this.bannerTimer);
      if (this.bannerInd === 0) {
        this.bannerInd = this.banners.length;
      }
      this.bannerInd--;
      this.autoBanner();
    },
    tabNext() {
      clearInterval(this.bannerTimer);
      this.bannerInd++;
      if (this.bannerInd === this.banners.length) {
        this.bannerInd = 0;
      }
      this.autoBanner();
    },
    loadTab(val, index) {
      this.loadInd = index;
      const space = 100 * this.loads[index].class.length;
      this.loads.forEach((item) => {
        if (item.code === this.loadTargetName) {
          item.class = "out";
        }
      });
      setTimeout(() => {
        this.loadTargetName = val.code;
        this.loads.forEach((item) => {
          item.class = "";
          if (item.code === val.code) {
            item.class = "move";
          }
        });
      }, space);
    },
    // 打开游戏
    openGame(val, index) {
      // 游戏列表数量超过3个
      if (this.hotsGameList[this.hotsGameInd].list.length > 3 && index === 2) {
        const link = this.hotsGameList.find(
          (item) => Number(item.gameType) === val.gameType
        ).link;
        this.$router.push({
          path: link,
        });
        return;
      }
      if (val.gameType === 2) {
        this.$router.push({
          path: "/game/egame",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (val.gameType === 6) {
        this.$router.push({
          path: "/game/fishing",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (!this.isLogin) {
        this.$confirm("请先登录账号，才能了解更多", "温馨提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
          customClass: "el-login-pop",
        })
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }

      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.moveScroll);
  },
};
</script>

<style lang="scss" scoped>
.banner {
  height: 700px;
  min-width: 1280px;
  position: relative;
  overflow: hidden;

  &:hover {
    .banner-next,
    .banner-prev {
      display: block;
    }
  }

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 100%;
    opacity: 0;
    transition: all 0.8s;

    &.hover {
      opacity: 1;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

  &-nav {
    white-space: nowrap;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
    gap: 30px;

    span {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      background: url("@/assets/images/nav-01.svg") no-repeat center center;
      background-size: 100% 100%;
      overflow: hidden;
      opacity: 0.5;

      &.hover {
        background: url("@/assets/images/nav-02.svg") no-repeat center center;
        background-size: 100% 100%;
        opacity: 1;
      }
    }
  }
}

@keyframes star {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.banner-prev,
.banner-next {
  position: absolute;
  top: 50%;
  transform: translateY(-80%);
  width: 40px;
  height: 80px;
  background: white;
  border-radius: 0 8px 8px 0;
  backdrop-filter: blur(5px);
  z-index: 2;
  cursor: pointer;
  box-shadow: 0 3px 7px 0 #7998fc59;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    background: url("@/assets/images/banner-arrow.webp") no-repeat center center;
    background-size: contain;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover {
    opacity: 0.5;
  }
}
.banner-prev {
  &::before {
    transform: rotate(180deg);
  }
}

.banner-next {
  right: 0;
  border-radius: 8px 0 0 8px;
}

.home-ad {
  margin: 20px auto 0;
  .yx-wid-12 {
    display: flex;
    align-items: center;
    height: 52px;
    background: white;
    border-radius: 30px;
    box-shadow: 0 10px 20px 0 rgba(53, 120, 246, 0.102);
    padding: 0 16px;
  }

  &-icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    background: url("@/assets/images/message.webp") no-repeat center center;
    background-size: cover;
  }

  &-scroll {
    flex: 1;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    color: #424245;
    position: relative;
  }

  &-text {
    display: inline-block;
    white-space: nowrap;
    // width: 100%;
    // animation: amin 10s linear infinite;

    .row {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      cursor: pointer;
    }
  }

  &-img {
    margin-top: 70px;
    cursor: pointer;
    text-align: center;

    img {
      transition: all 0.4s;

      &:hover {
        transform: scale(1.04);
      }
    }
  }
}

.home-load-flex {
  display: flex;
  align-items: center;

  .img {
    width: 880px;
  }

  .flex-left {
    flex: 1;

    .img {
      width: 115%;
      margin-left: -15%;
    }
  }

  .flex-right {
    background: url("@/assets/images/load-bg.png") no-repeat;
    background-size: 100% 100%;
    width: 456px;
    height: 580px;
    box-shadow: 0 6px 20px 3px rgba(201, 211, 237, 0.5);
    border-radius: 24px;
    flex: none;
    padding: 36px 24px;

    .load-tab {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      li {
        width: 132px;
        height: 44px;
        background: url("@/assets/images/tab-bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #788b9d;
        font-size: 16px;
        cursor: pointer;
        margin: 0 10px;

        &.hover {
          color: white;
          background: url("@/assets/images/tab-bg1.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .load-sec {
      padding: 0 10px;

      strong {
        font-size: 40px;
        margin-top: 32px;
        display: block;
      }

      p {
        line-height: 28px;
        font-size: 18px;
        margin-top: 12px;
        text-align: justify;
      }
    }

    .load-add {
      display: flex;
      align-items: center;
      padding: 24px 10px 0;

      &-code,
      &-web {
        background: #f4faff;
        border: 1px solid #fff;
        border-radius: 12px;
        padding: 23px 31px 12px;
        height: 224px;
        width: 182px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          text-align: center;
          line-height: 22px;

          span {
            font-size: 16px;
            margin-top: 20px;
            display: block;
          }

          p {
            font-size: 12px;
            margin-top: 2px;
            white-space: nowrap;
          }
        }
      }

      &-web {
        margin-left: 22px;

        .add {
          width: 120px;
          height: 120px;

          a {
            color: #2599f8;
            word-wrap: break-word;
          }
        }
      }

      &-code {
        .img {
          width: 120px;
        }
      }
    }
  }
}

.load-add-pc {
  padding: 0 10px;
  margin-top: 32px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  li {
    width: 120px;
    height: 168px;
    background: #f0f6ff;
    border: 1px solid #fff;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 60%;
    }

    .btn {
      background: url("@/assets/images/tab-bg1.png") no-repeat;
      line-height: 22px;
      height: 22px;
      width: 68px;
      font-size: 12px;
      background-size: 100% 100%;
      cursor: pointer;
      box-shadow: 0 2px 5px #5da7eb;
      border-radius: 11px;
      color: white;
      text-align: center;
      margin-top: 12px;
    }
  }
}

@keyframes imgStar {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes imgOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-10%);
    opacity: 0;
  }
}

@keyframes imgStarHots {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes imgOutHots {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-20%);
    opacity: 0;
  }
}
.sj-title {
  display: flex;
  align-items: flex-end;
  font-size: 28px;
  font-weight: bold;
  margin: 50px auto;
  h3 {
    color: var(--color-black);
    font-size: 28px;
  }
  .span {
    color: var(--color-gray);
  }
}
.sj-download {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  .row {
    background: white;
    border-radius: 18px;
    box-shadow: 2px 4px 12px 0 #00000014;
    display: flex;
    justify-content: space-around;
    padding: 32px;
    &-left {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
    }
    &-right {
      height: 300px;
      flex: 1;
      img {
        height: 100%;
      }
    }
    h4 {
      font-size: 24px;
      color: var(--color-black);
      margin-bottom: 20px;
    }

    .code {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      p {
        color: #6e6e73;
        font-size: 12px;
      }
    }
    .ym-add {
      display: flex;
      align-items: center;
      width: 60%;
      margin: 0 auto;
      white-space: nowrap;
      .text-over {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--color-theme);
        width: 120px;
      }
    }
  }
}
.sj-game {
  &::after {
    content: "";
    height: 0;
    overflow: hidden;
    display: block;
    clear: both;
  }
  .row {
    height: 265px;
    width: 305px;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 18px;
    box-shadow: 2px 4px 12px 0 #00000014;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 30px;
    transition: all 0.4s;
    cursor: pointer;
    &:hover {
      transform: scale(1.02);
      box-shadow: 4px 8px 12px 0 #00000024;
    }
    &:first-child {
      height: 550px;
      float: left;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 20px;
    }
    &:nth-child(4),
    &:nth-child(7) {
      margin-right: 0;
    }
    h3 {
      color: #1d1d1f;
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      will-change: transform;
      margin-bottom: 10px;
    }
    p {
      color: #6e6e73;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.sj-service {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  .row {
    background-position: center bottom;
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 18px;
    box-shadow: 2px 4px 12px 0 #00000014;
    flex: 1;
    height: 430px;
    padding: 60px 32px 0;
    position: relative;
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
    &:hover {
      transform: scale(1.02);
      box-shadow: 4px 8px 12px 0 #00000024;
    }
    h3 {
      color: #1d1d1f;
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 10px;
    }
    p {
      color: #6e6e73;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
  }
}
</style>
